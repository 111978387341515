




























import { Component, Prop, Vue } from 'vue-property-decorator'
import { Option, SavedTaskItem } from './types'

@Component
export default class Actions extends Vue {
  @Prop({ type: Object, required: true }) readonly item!: SavedTaskItem
  @Prop({ type: Boolean }) readonly canCreateTask!: boolean
  @Prop({ type: Boolean }) readonly canDelete!: boolean
  @Prop({ type: Boolean }) readonly canEdit!: boolean

  get options (): Option[] {
    return [
      ...(this.canEdit ? [this.editOption] : []),
      ...(this.canCreateTask ? [this.createTaskOption] : []),
      ...(this.canDelete ? [this.deleteOption] : []),
    ]
  }

  get createTaskOption (): Option {
    return {
      key: 'createTask',
      label: this.item.subtask
        ? this.$t('tasks.taskItems.copySubtask').toString()
        : this.$t('tasks.taskItems.captionCreate').toString(),
      action: () => { this.$emit('createTask') },
    }
  }

  get deleteOption (): Option {
    return {
      key: 'delete',
      label: this.$t('tasks.taskItems.deleteItem').toString(),
      action: () => { this.$emit('delete') },
    }
  }

  get editOption (): Option {
    return {
      key: 'edit',
      label: this.$t('common.edit').toString(),
      action: () => { this.$emit('edit') },
    }
  }
}
